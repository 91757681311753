import { useEffect } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { Provider as AuthProvider, useSession, useToken } from '@drive/auth'
import { ErrorPage, useInitialize } from '@drive/shared'
import AppRouter from './AppRouter'
import { getToken, setConfig, setToken } from '@skywatch/core'

declare global {
  interface Window {
    isAdmin: boolean
  }
}
const { VITE_VOOM_API_PATH } = import.meta.env

window.isAdmin = true

const App = () => {
  const { withInitialize, setInitialized } = useInitialize()
  const tokenData = useToken()
  const user = useSession()

  useEffect(() => {
    if (!tokenData) {
      setInitialized()
      return
    }
    user.getUser(tokenData.userId).then(() => {
      user.setData({ role: tokenData.userRole })
      setInitialized()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  setConfig({
    baseUrl: VITE_VOOM_API_PATH,
    setToken: setToken,
    getToken: getToken,
  })
  return withInitialize(<AppRouter />)
}

const Fallback = ({ error, resetErrorBoundary }: FallbackProps) => (
  <ErrorPage error={error} reset={resetErrorBoundary} />
)

export default () => (
  <ErrorBoundary FallbackComponent={Fallback}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </ErrorBoundary>
)
