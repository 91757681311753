import {
  Button,
  ExpandIcon,
  OdometerIcon,
  Table,
  TableContainer,
  TableFullRow,
  TableTd,
  TableTh,
  TableThead,
  TableTr,
  Pagination,
  useDialog,
} from '@skywatch/ui'
import { useEffect, useState } from 'react'
import { useOdometerManager } from '../hooks'
import { ApprovedOdometer } from '../store'
interface TableRow {
  uploadedDate: string
  vehicle: string
  approvedMiles: number
  commercialMiles: number
  nonCommercialMiles: number
  imageUrl: string
}

interface TableColumn {
  key: keyof TableRow
  header: JSX.Element | string
  onClickHeader?: () => void
}
export default function PreviousOdometers() {
  const columns: TableColumn[] = [
    { key: 'uploadedDate', header: 'Date Uploaded' },
    { key: 'vehicle', header: 'Vehicle' },
    { key: 'approvedMiles', header: 'Odometer Reading Value' },
    { key: 'nonCommercialMiles', header: 'Personal Driven Miles' },
    { key: 'commercialMiles', header: 'Commercial Driven Miles ' },
    { key: 'imageUrl', header: 'Odometer Images' },
  ]
  const { state } = useOdometerManager()
  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState([] as ApprovedOdometer[])
  const itemsPerPage = 5

  const [popupImage, setPopUpImage] = useState('')
  const { Dialog, setOpen } = useDialog({ outsideClickShouldClose: true })

  const onPageChange = (page: number) => {
    setCurrentPage(page)
  }

  const setTableData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    const data = state.approvedOdometer.slice(startIndex, endIndex)
    setData(data)
  }
  useEffect(() => {
    setTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, state.approvedOdometer])

  return (
    <div className="flex flex-col">
      <div className="mb-8 text-base font-semibold text-gray-900 ">Previous odometers</div>

      <TableContainer className="overflow-x-auto">
        <Table>
          <TableThead>
            <tr>
              {columns.map((column, index) => (
                <TableTh key={index} onClick={column.onClickHeader}>
                  {column.header}
                </TableTh>
              ))}
            </tr>
          </TableThead>
          <tbody>
            {state.approvedOdometer.length === 0 ? (
              <TableFullRow cols={6}>
                <div className="flex w-full flex-col items-center self-center text-gray-300">
                  <OdometerIcon className="h-18 w-18 stroke-gray-300 stroke-1" />
                  You have no previous odometers yet
                </div>
              </TableFullRow>
            ) : (
              <>
                {data.map((row, i) => (
                  <TableTr key={i}>
                    {columns.map((column, columnIndex) => {
                      switch (column.key) {
                        case 'imageUrl':
                          return (
                            <TableTd key={columnIndex}>
                              <div
                                className="flex flex-row items-center space-x-2 "
                                onClick={() => {
                                  setPopUpImage(row.imageUrl)
                                  setOpen(true)
                                }}
                              >
                                <div>
                                  <img className="h-8 w-14 rounded-md object-cover" src={row.imageUrl} />{' '}
                                </div>
                                <ExpandIcon stroke="#101828" strokeWidth="1.3" />
                              </div>
                            </TableTd>
                          )
                        case 'approvedMiles':
                          return <TableTd key={columnIndex}>{row[column.key].toLocaleString('en-US')}</TableTd>

                        default:
                          return <TableTd key={columnIndex}>{row[column.key]}</TableTd>
                      }
                    })}
                  </TableTr>
                ))}
              </>
            )}
          </tbody>
        </Table>
      </TableContainer>
      {Math.ceil(state.approvedOdometer.length / itemsPerPage) > 1 && (
        <Pagination
          defaultPage={1}
          maxPages={Math.ceil(state.approvedOdometer.length / itemsPerPage)}
          visibleRange={5}
          onPrev={onPageChange}
          onNext={onPageChange}
          onClick={onPageChange}
        />
      )}
      <Dialog className="grid place-items-center">
        <img className="max-h-[90vh] rounded-md object-contain" src={popupImage} alt="Popup" />
        <Button buttonType="gray" variant="outline" className="mt-3" onClick={() => setOpen(false)}>
          Close
        </Button>
      </Dialog>
    </div>
  )
}
